body[data-theme="light"] {

    --background: #fff;
     --accents-1: #fafafa;
     --accents-2: #eaeaea;
     --accents-3: #999;
     --accents-4: #888;
     --accents-5: #666;
     --accents-6: #444;
     --accents-7: #333;
     --accents-8: #111;
    --foreground: #000;

    --links-1: #8338ec;
    --links-2: #0761d1;
    --links-3: rgb(236,244,254);
    --links-4: rgb(43,102,207);

    --gradient-color-1: #ffbe0b; 
    --gradient-color-2: #fb5607; 
    --gradient-color-3: #ff006e; 
    --gradient-color-4: #8338ec;
    --gradient-color-5: #3a86ff;

    --gradient-stop-1: 0%; 
    --gradient-stop-2: 25%; 
    --gradient-stop-3: 50%; 
    --gradient-stop-4: 75%; 
    --gradient-stop-5: 100%;
}

body[data-theme="dark"] {

    --background: rgb(19, 25, 31);
     --accents-1: rgb(19, 25, 31);
     --accents-2: #333;
     --accents-3: #444;
     --accents-4: #666;
     --accents-5: #888;
     --accents-6: #999;
     --accents-7: #eaeaea;
     --accents-8: #fafafa;
    --foreground: #fff;

    --links-1: #7033d4;
    --links-2: #7033d4;
    --links-3: #FF4983;
    --links-4: rgb(122,190,122);

    --gradient-color-1: #ffd6ff; 
    --gradient-color-2: #e7c6ff; 
    --gradient-color-3: #c8b6ff; 
    --gradient-color-4: #b8c0ff;
    --gradient-color-5: #bbd0ff;

    --gradient-stop-1: 0%; 
    --gradient-stop-2: 25%; 
    --gradient-stop-3: 50%; 
    --gradient-stop-4: 75%; 
    --gradient-stop-5: 100%;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;

    --gradient-direction: to bottom right;
    --gradient: linear-gradient(
      var(--gradient-direction),
      var(--gradient-color-1) var(--gradient-stop-1),
      var(--gradient-color-2) var(--gradient-stop-2),
      var(--gradient-color-3) var(--gradient-stop-3),
      var(--gradient-color-4) var(--gradient-stop-4),
      var(--gradient-color-5) var(--gradient-stop-5)
    );
    
    --gradient-2: linear-gradient(
      to right,
      var(--gradient-color-2) var(--gradient-stop-2),
      var(--gradient-color-4) var(--gradient-stop-4)
    );
}

body {
  background-color: var(--background);
  color: var(--foreground);
  transition: background-color 0.3s ease, color 0.3s ease;
  background-image: 

  linear-gradient(to right, var(--accents-1) 1px, transparent 1px),
  linear-gradient(to bottom, var(--accents-1) 1px, transparent 1px);

  background-size: 50px 50px, 50px 50px, 100% 100%;
  background-repeat: repeat, repeat, no-repeat;
  font-family: 'Monaco', sans-serif;
}

li, a, button {
    font-weight: 400;
    font-size: 1.1rem;
    text-decoration: none;
    color: var(--foreground);
}

html {
    scroll-padding-top: 100px;
}

@media screen and (max-width: 768px) {
    html {
        scroll-padding-top: 70px;
    }

    body{
        font-size: 0.8rem;
    }
}