.contact-container {
    max-width: 650px;
    margin: auto;
    padding: 2rem;
    border-radius: 15px;
    position: relative;
    background: none;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--foreground);
}

.contact-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    opacity: 0.3;
    z-index: -1;
}

.contact-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.description{
    max-width: 450px;
    margin: auto;
    padding: 2rem;
    border-radius: 15px;
    position: relative;
    background: none;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-description {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1.5rem;
    z-index: 1;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    z-index: 1;
}

.form-group {
    position: relative;
    padding: 1px;
    border-radius: 7px;
    z-index: 1;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: inherit;
    background-color: var(--background);
    color: var(--foreground);
    z-index: 1;
    box-shadow: 0px 0px 1px 1px var(--gradient-color-3);   
}

.form-textarea {
    min-height: 100px;
}

.submit-button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    background: var(--gradient-color-3);
    color: var(--background);
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: filter 0.3s ease, background-color 0.3s ease;
    z-index: 1;
    opacity: 1;
    max-width: 100px;
}

.submit-button:hover {
    filter: brightness(2.0);
}

.form-group:hover::before {
    opacity: 0.5;
}

/* Centered loading spinner */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 200px; /* Matches form height */
  }
  
  .loading-spinner {
    border: 4px solid var(--background); /* Background color */
    border-top: 4px solid var(--gradient-color-3); /* Accent color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  /* Spinner animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Success message styling */
  .success-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px; /* Matches form height */
    text-align: center;
  }
  
  .success-message {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--gradient-color-3);
    background: var(--background);
    padding: 1rem;
    border-radius: 10px;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  }
  