.home-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40vh;
    text-align: center;
  }
  
  .home-intro h1 {
    font-size: 2.5rem;
    margin: 0;
    font-weight: 500;
    line-height: 1;
  }
  
  .home-intro h2 {
    font-size: 3.5rem;
    margin: 0;
    font-weight: 700;
    line-height: 1;
  }
  
  .home-intro {
    font-size: 1.5rem;
    margin-top: 8px;
    color: var(--accents-7); 
    line-height: 1;
  }

 .home-intro .name-outline {
    font-size: 5.5rem; 
    font-weight: 700; 
    color: var(--background);
    position: relative;
    display: inline-block; 
  }
  
  /* .home-intro .name-outline::before {
    content: attr(data-text); 
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 100%; 
    height: 100%; 
    background: var(--gradient); 
    filter: blur(40px); 
    opacity: 0.5; 
    z-index: -1; 
    text-align: center; 
    
  }  */

/*  Gradient Color Overlay */
.home-intro .name-outline {
    background: var(--gradient); 
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent; 
    display: inline-block;
    position: relative;
    opacity: 0.5;
}  