.footer {
    position: fixed; 
    bottom: 0; 
    left: 0; 
    width: 100%; 
    padding: 15px 0;
    background-color: var(--background);
    text-align: center;
    color: var(--foreground);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2; 
}

.footer-content {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 1rem;
    color: var(--foreground);
    font-weight: 500;
    text-decoration: none;
}

.heart {
    color: red;
    margin: 0px;
    opacity: 1;
}

.gradient-separator {
    display: flex;
    position: fixed; 
    bottom: 60px; 
    left: 50%; 
    transform: translateX(-50%);
    max-width: 500px;
    width: 100%;
    height: 10px;
    background: var(--gradient);
    filter: blur(20px);
    opacity: 0.3;
}

.gradient-separator::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--gradient);
    z-index: 1;
    border-radius: inherit;
}
