.spacery4{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: auto;
}

.headding {
    display: flex;
    width: 1080px;
    justify-content: flex-start;
    gap: 2rem;
    flex-direction: column;
}
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card {
    margin: 15px; 
    display: flex;
    background-color: var(--geist-background);
    position: relative;
    border-radius: 15px;
    flex-direction: row;
    border: 1.5px solid var(--accents-2);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;    
}

.card:hover {
    transform: scale(1.00);
}

.icon {
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
    margin: 0 5px;
    transition: opacity 500ms ease;
}

.link-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    visibility: hidden;
}

.link-icon2 {
    visibility: hidden;
    pointer-events: auto;
}

.card:hover .link-icon {
    visibility: visible;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 5rem;
    height: 5rem;
}

.company-logo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid var(--accents-2);
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    color: var(--accents-7);
    width: 1000px;
}

.position {
    text-align: left;
    padding-bottom: 0.5rem;
    font-size: 1.3rem;
    font-weight: 700;
}

.company-description {
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    padding: 0;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    color: var(--accents-6);
    transition: opacity 0.3s ease-out, height 0.3s ease-out, padding 0.3s ease-out;
}

.company-name {
    text-align: left;
    font-size: 1rem;
    color: var(--accents-7);
}

.expandButton {
    background-color: var(--links-3);
    color: var(--links-4);
    padding: 10px;
    border-radius: 25px;
    text-decoration: none;
    transition: background-color 300ms ease-in;
    display: block;
    text-align: center;
    transition: ease-in;
    cursor: pointer;
    font-size: smaller;
    font-weight: 700;
}

.card.active .company-description {
    opacity: 1;
    max-height: 1500px;
    padding: 0.8em;
    transition: opacity 0.5s ease-in, max-height 0.5s ease-in, padding 0.5s ease-in;
}

.card.active .company-name.forwork {
    visibility: hidden;
}


.languages i {
    margin-right: 8px;
    font-size: 24px;
    padding-top: 15px;
    color: var(--accents-5);
}

.company-name.projects{
    color: var(--accents-6);
}

.textDiv{
    display: flex;
    gap: 1rem;
}

.card:hover{
    background-color: var(--accents-1);
}

.terminal-text.qq.work{
    display: none;
}

.headding.work{
    display: flex;
}

.spacery4.work{
    display: none;
}

.spacery456{
    display: block;
    height: 15vh;
}

@media screen and (max-width: 1190px){
    .container {
        pointer-events: none;
    }

    .card {
        flex-direction: column;
        align-items: flex-start;
        pointer-events: none;
    }


    .link-icon {
        display: None;
    }

    .link-icon2 {
        visibility: visible;
        pointer-events: auto;
    }

    .card:active .company-description {
        opacity: 1;
        max-height: 1500px;
        padding: 0.8em;
        transition: opacity 0.5s ease-in, height 0.5s ease-in, padding 0.5s ease-in;
    }

    .card:active .company-name.forwork {
        visibility: hidden;
    }

    .card:active .link-icon {
        visibility: hidden;
    }

    .expandButton {
        display: inline;
    }

    .headding {
        display: flex;
        padding-left: 1.2rem;
    }

    .aniDiv{
        display: None;
    }

    .link-icon2:hover .company-description{
        display: None;
    }

    .info-container {
        width: 90vw;
    }

    .logo-container {
        margin-left: 0.8rem;
        margin-top: 0.8rem
    }

    .terminal-text.qq{
        display: none;
    }

}

@media screen and (max-width: 768px) {
    .container {
        pointer-events: none;
    }

    .card {
        flex-direction: column;
        align-items: flex-start;
        pointer-events: none;
    }
    
    .link-icon {
        display: None;
    }
    
    .link-icon2 {
        visibility: visible;
        pointer-events: auto;
    }

    .link-icon2:hover .company-description{
        display: None;
    }

    .card:active .company-description {
        opacity: 1;
        max-height: 1500px;
        padding: 0.8em;
        transition: opacity 0.5s ease-in, height 0.5s ease-in, padding 0.5s ease-in;
    }

    .card:active .company-name.forwork {
        visibility: hidden;
    }

    .expandButton {
        display: inline;
    }

    .aniDiv {
        display: None;
    }

    .leftAboutImage{
        padding-left: 1rem;
    }

    .logo-container{
        margin-left: 0.8rem;
        margin-top: 0.8rem
    }

    .info-container {
        width: 90vw;
    }

    .terminal-text.qq{
        display: none;
    }

    .card.active .company-description {
        padding-left: 0.2rem;
    }
}