.navbar-wrapper {
  position: relative;
  margin: 30px auto;
  max-width: 400px; 
  border-radius: 15px; 
  padding: 1px; 
  z-index: 0; 
  background: none; 
}

.navbar-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit; 
  background: var(--gradient); 
  opacity: 0.3; 
  z-index: -1;
}

.navbar-container {
  position: relative;
  z-index: 1; 
  width: 100%; 
  border-radius: 15px; 
  background-color: var(--accents-1); 
  color: var(--foreground);
  padding: 12px 24px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-container::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--gradient);
  transform: translate3d(-3px, 9px, 0) scale(1.02);
  filter: blur(20px);
  opacity: 0.15;
  transition: opacity 0.3s;
  border-radius: inherit;
}

.navbar-container::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit;
}

.navbar-container .link {
  font-size: 1rem;
  color: var(--foreground);
  text-decoration: none;
  position: relative;
  transition: color 0.1s ease;
  font-weight: 500;
}

.navbar-container .link::before {
  content: "";
  position: absolute;
  top: -12px; 
  right: -18px;
  bottom: -12px;
  left: -18px;
  background: var(--gradient);
  opacity: 0;
  transition: filter 0.3s ease, opacity 0.3s ease;
  border-radius: inherit;
  z-index: -1; 
}

.navbar-container .link:hover::before {
  filter: blur(20px); 
  opacity: 0.4;
}

.navbar-container .link:hover {
  background: var(--gradient-2);
  background-clip: text;
  -webkit-background-clip: text; 
  -moz-background-clip: text;    
  -ms-background-clip: text;     
  -o-background-clip: text;      
  color: transparent;
}


.navbar-container .theme-toggle {
  cursor: pointer;
}


